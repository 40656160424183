
const PRODUCTION_API_URL = 'https://roykornblum-backend-production.up.railway.app/api';
const DEVELOPMENT_API_URL = 'http://localhost:8000/api';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? PRODUCTION_API_URL 
  : DEVELOPMENT_API_URL;


// Helper function for handling responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    throw new Error(errorData?.detail || 'API request failed');
  }
  return response.json();
};

// Generic request function with error handling
const makeRequest = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`API Error (${endpoint}):`, error);
    throw error;
  }
};

// API functions
export const createOrder = async (orderData, transaction_id) => {
  console.log('Creating order with data:', JSON.stringify(orderData));
  return makeRequest(`/orders/?transaction_id=${transaction_id}`, {
      method: 'POST',
      body: JSON.stringify(orderData),
  });
};

// Create pre-order
export const createPreOrder = async (orderData) => {
  console.log('Creating pre-order with data:', JSON.stringify(orderData));
  return makeRequest('/orders/pre-order', {
    method: 'POST',
    body: JSON.stringify(orderData),
  });
};

export const getOrderStatus = async (orderId) => {
  return makeRequest(`/payments/status/${orderId}`);
};

export const fetchOrders = async () => {
  return makeRequest('/orders/', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
  });
};

export const updateOrderStatus = async (orderId, status) => {
  return makeRequest(`/orders/${orderId}`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
  });
};

export const markOrderAsShipped = async (orderId) => {
  return makeRequest(`/payments/shipping/${orderId}`, {
    method: 'POST',
  });
};

export const login = async (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  
  const response = await fetch(`${API_BASE_URL}/auth/token`, {
      method: 'POST',
      body: formData,
  });
  
  const data = await response.json();
  localStorage.setItem('authToken', data.access_token);
  return data;
};

export const verifyAuth = async () => {
  return makeRequest('/auth/verify', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
  });
};

// contact form
export const sendContactForm = async (formData) => {
  console.log('Sending contact form:', API_BASE_URL);
  const response = makeRequest('/contact/', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
  return response;
};

export const exportOrders = async (exportType = 'paid') => {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/export/?export_type=${exportType}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (!response.ok) {
      throw new Error('Export failed');
    }

    const contentDisposition = response.headers.get('Content-Disposition');
    const filename = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/["']/g, '')
      : `${exportType}_orders.csv`;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error exporting orders:', error);
    throw error;
  }
}


import React from 'react';
import SiteFooter from '../Footer/SiteFooter';
import { ArrowRight } from 'lucide-react';


const AccessibilityPage = ({onBack}) => {
  return (
    <div className="min-h-screen bg-black pt-24">
            {/* Navigation Area */}
      <div className="absolute top-10 left-0 right-0 h-24 flex items-center px-8">
        <button 
          onClick={onBack}
          className="text-gray-400 hover:text-white flex items-center gap-2 transition-colors duration-200"
        >
          <ArrowRight className="w-5 h-5" />
          חזרה לדף הבית
        </button>
      </div>
      <div className="max-w-4xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold text-white mb-8">הצהרת נגישות</h1>
        
        <div className="prose prose-invert max-w-none">
          <section className="mb-8">
            <p className="text-gray-300 leading-relaxed">
              עודכן לאחרונה: ינואר 2025
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">מחויבות לנגישות</h2>
            <p className="text-gray-300 leading-relaxed">
              אתר זה מחויב להנגשת השירות לאנשים עם מוגבלויות בהתאם לתקנות שוויון זכויות
              לאנשים עם מוגבלות (התאמות נגישות לשירות), תשע"ג-2013.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">התאמות נגישות באתר</h2>
            <p className="text-gray-300 leading-relaxed">
              האתר כולל את ההתאמות הבאות:
              - תמיכה בניווט מקלדת מלא
              - תיאורי תמונות עבור קוראי מסך
              - ניגודיות צבעים גבוהה
              - אפשרות להגדלת טקסט
              - תמיכה בקוראי מסך
              - מבנה אתר ברור וקבוע
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">אמצעי נגישות נוספים</h2>
            <p className="text-gray-300 leading-relaxed">
              - כל התמונות באתר כוללות תיאור טקסטואלי חלופי
              - כל הטפסים באתר נגישים לניווט באמצעות מקלדת
              - האתר תומך בשינוי גודל הטקסט באמצעות דפדפן
              - האתר מותאם לשימוש בטלפונים ניידים וטאבלטים
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">הצהרת נגישות לפי תקן WCAG</h2>
            <p className="text-gray-300 leading-relaxed">
              האתר נבנה בהתאם להנחיות WCAG 2.1 ברמה AA ועומד בדרישות תקנות הנגישות.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">פניות בנושא נגישות</h2>
            <p className="text-gray-300 leading-relaxed">
              נתקלתם בבעיית נגישות? נשמח לקבל משוב:
              - ניתן לפנות אלינו באמצעות טופס יצירת הקשר באתר
              - זמן המענה המקסימלי לפניה הוא 48 שעות
            </p>
          </section>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
};

export default AccessibilityPage;
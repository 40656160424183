import React from 'react';
import SiteFooter from '../Footer/SiteFooter';
import { ArrowRight } from 'lucide-react';

const PrivacyPage = ({onBack}) => {
  return (
    <div className="min-h-screen bg-black pt-24">
            {/* Navigation Area */}
      <div className="absolute top-10 left-0 right-0 h-24 flex items-center px-8 ">
        <button 
          onClick={onBack}
          className="text-gray-400 hover:text-white flex items-center gap-2 transition-colors duration-200"
        >
          <ArrowRight className="w-5 h-5" />
          חזרה לדף הבית
        </button>
      </div>
      <div className="max-w-4xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold text-white mb-8">מדיניות פרטיות</h1>
        
        <div className="prose prose-invert max-w-none">
          <section className="mb-8">
            <p className="text-gray-300 leading-relaxed">
              עודכן לאחרונה: ינואר 2025
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. כללי</h2>
            <p className="text-gray-300 leading-relaxed">
              אנו מכבדים את פרטיותך ומחויבים להגן על המידע האישי שלך. מדיניות זו מסבירה כיצד אנו אוספים,
              משתמשים ומגנים על המידע האישי שלך בעת השימוש באתר שלנו.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. המידע שאנו אוספים</h2>
            <p className="text-gray-300 leading-relaxed">
              2.1. מידע שאתה מספק לנו:<br/>
              - שם מלא<br/>
              - כתובת למשלוח<br/>
              - כתובת דואר אלקטרוני<br/>
              - מספר טלפון<br/>
              - פרטי תשלום (מועברים ישירות לספק שירותי הסליקה)<br/><br/>
              
              2.2. מידע שנאסף אוטומטית:<br/>
              - כתובת IP<br/>
              - מידע על המכשיר והדפדפן<br/>
              - נתוני שימוש באתר
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. השימוש במידע</h2>
            <p className="text-gray-300 leading-relaxed">
              אנו משתמשים במידע שנאסף לצורך:<br/>
              3.1. עיבוד וביצוע הזמנות<br/>
              3.2. משלוח עדכונים לגבי סטטוס ההזמנה<br/>
              3.3. מענה לפניות ובקשות שירות<br/>
              3.4. שיפור השירות והחווייה באתר<br/>
              3.5. שליחת עדכונים שיווקיים (בכפוף להסכמתך)
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. שיתוף מידע</h2>
            <p className="text-gray-300 leading-relaxed">
              4.1. אנו משתפים מידע אישי רק עם:<br/>
              - חברות משלוחים לצורך אספקת ההזמנה<br/>
              - ספקי שירותי סליקה לצורך עיבוד התשלום<br/>
              - גורמים מוסמכים על פי דין<br/><br/>
              
              4.2. איננו מוכרים או משכירים את המידע האישי שלך לצדדים שלישיים.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. אבטחת מידע</h2>
            <p className="text-gray-300 leading-relaxed">
              5.1. אנו נוקטים באמצעי אבטחה מתקדמים להגנה על המידע האישי שלך.<br/>
              5.2. כל העברת מידע רגיש מוצפנת באמצעות פרוטוקול SSL.<br/>
              5.3. הגישה למידע מוגבלת לעובדים מורשים בלבד.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. זכויותיך</h2>
            <p className="text-gray-300 leading-relaxed">
              בהתאם לחוק הגנת הפרטיות, יש לך זכות:<br/>
              6.1. לעיין במידע האישי שלך<br/>
              6.2. לבקש תיקון מידע לא מדויק<br/>
              6.3. לבקש מחיקת המידע שלך<br/>
              6.4. להתנגד לעיבוד המידע למטרות שיווק
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. יצירת קשר בנושאי פרטיות</h2>
            <p className="text-gray-300 leading-relaxed">
              לכל שאלה או בקשה בנוגע למדיניות הפרטיות או המידע האישי שלך,
              ניתן ליצור קשר באמצעות טופס יצירת הקשר באתר.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. עדכונים למדיניות הפרטיות</h2>
            <p className="text-gray-300 leading-relaxed">
              אנו עשויים לעדכן מדיניות זו מעת לעת. שינויים מהותיים יפורסמו באתר,
              והמשך השימוש באתר לאחר פרסום השינויים יהווה הסכמה למדיניות המעודכנת.
            </p>
          </section>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
};

export default PrivacyPage;
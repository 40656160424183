import React, { useState } from 'react';
import { Truck, MapPin } from 'lucide-react';

const ShippingStep = ({ selectedPlan, onBack, onNext }) => {
  const [selectedMethod, setSelectedMethod] = useState('delivery');

  const handleSubmit = () => {
    onNext({
      method: selectedMethod,
      cost: selectedMethod === 'delivery' ? 29 : 0
    });
  };

  return (
    <div className="w-full max-w-2xl mx-auto" dir="rtl">
      <div className="bg-[#13111C] p-8 rounded-2xl">
        {/* Header */}
        <div className="text-white mb-6 text-center">
          <h2 className="text-2xl font-bold mb-2">בחירת שיטת משלוח</h2>
          <p className="text-gray-400">
            {selectedPlan.title} - {selectedPlan.type === 'bundle' 
              ? `₪${selectedPlan.pricePerBook} לספר (סה״כ ${selectedPlan.price})` 
              : selectedPlan.price}
          </p>
        </div>

        {/* Shipping Options */}
        <div className="space-y-4">
          {/* Delivery Option */}
          <div 
            className={`p-6 border rounded-xl cursor-pointer transition-all
              ${selectedMethod === 'delivery' 
                ? 'border-purple-500 bg-purple-500/10' 
                : 'border-gray-700 hover:border-gray-600'}`}
            onClick={() => setSelectedMethod('delivery')}
          >
            <div className="flex items-center gap-4">
              <div className="bg-purple-500/20 p-3 rounded-lg">
                <Truck className="w-6 h-6 text-purple-500" />
              </div>
              <div className="flex-1">
                <h4 className="font-semibold text-lg text-white">משלוח עד הבית</h4>
                <p className="text-gray-400 mt-1">זמן אספקה - עד 14 ימי עסקים</p>
                <p className="text-purple-500 mt-2 font-medium">₪29</p>
              </div>
            </div>
          </div>

          {/* Pickup Option */}
          <div 
            className={`p-6 border rounded-xl cursor-pointer transition-all
              ${selectedMethod === 'pickup' 
                ? 'border-purple-500 bg-purple-500/10' 
                : 'border-gray-700 hover:border-gray-600'}`}
            onClick={() => setSelectedMethod('pickup')}
          >
            <div className="flex items-center gap-4">
              <div className="bg-purple-500/20 p-3 rounded-lg">
                <MapPin className="w-6 h-6 text-purple-500" />
              </div>
              <div className="flex-1">
                <h4 className="font-semibold text-lg text-white">איסוף עצמי</h4>
                <p className="text-gray-400 mt-1">גבעתיים, יד מרדכי 13</p>
                <p className="text-gray-400 text-sm mt-1">בתיאום מראש (בוואטסאפ):</p>
                <p className="text-gray-400 text-sm mt-1">050-3621620</p>
                <p className="text-green-500 mt-2 font-medium">חינם</p>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex gap-4 mt-8">
          <button
            onClick={onBack}
            className="flex-1 bg-gray-700 hover:bg-gray-600 text-white py-4 
                     transition-colors duration-300 rounded-xl"
          >
            חזרה
          </button>
          <button
            onClick={handleSubmit}
            className="flex-1 bg-purple-600 hover:bg-purple-700 text-white py-4 
                     transition-colors duration-300 rounded-xl"
          >
            המשך
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShippingStep;
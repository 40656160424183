import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCircle2, XCircle, ArrowRight } from 'lucide-react';
import { getOrderStatus} from '../../services/api';


const PaymentResult = ({ status }) => {  // Remove onBack from props
  const { orderId } = useParams();
  const navigate = useNavigate();  // Add this hook
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkOrderStatus = async () => {
      try {
        const response = await getOrderStatus(orderId);
        if (response.ok) {
          const data = await response.json();
          setOrderDetails(data);
        }
      } catch (error) {
        console.error('Error checking order status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (orderId) {
      checkOrderStatus();
    }
  }, [orderId]);

  return (
    <div className="min-h-screen bg-[#0A071B] pt-32">
      {/* Navigation Area */}
      <div className="absolute top-10 left-0 right-0 h-24 flex items-center px-8 bg-[#0A071B]">
        <button 
          onClick={() => navigate('/')}  // Replace onBack with direct navigation
          className="text-gray-400 hover:text-white flex items-center gap-2 transition-colors duration-200"
        >
          <ArrowRight className="w-5 h-5" />
          חזרה לדף הבית
        </button>
      </div>

      <div className="max-w-md mx-auto p-4">
        <div className="bg-[#13111C] rounded-2xl p-8 text-center" dir="rtl">
          {isLoading ? (
            <div className="text-white">טוען...</div>
          ) : status === 'success' ? (
            <>
              <CheckCircle2 className="w-16 h-16 text-green-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-white mb-4">התשלום התקבל בהצלחה!</h2>
              <p className="text-gray-300 mb-6">
                ההזמנה שלך מספר {orderId} התקבלה ותטופל בהקדם.
                {orderDetails?.shipping_info?.email && (
                  <span className="block mt-2">
                    אישור נשלח לכתובת המייל {orderDetails.shipping_info.email}
                  </span>
                )}
              </p>
            </>
          ) : (
            <>
              <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-white mb-4">התשלום נכשל</h2>
              <p className="text-gray-300 mb-6">
                מצטערים, אך התשלום לא הושלם בהצלחה.
                <span className="block mt-2">אנא נסה שוב או צור קשר עם שירות הלקוחות.</span>
              </p>
            </>
          )}

          {/* <button
            onClick={() => navigate('/')}  // Replace onBack with direct navigation
            className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-xl 
                     transition-colors duration-300 w-full"
          >
            חזרה לדף הבית
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
import React, { useState, useEffect } from 'react';
import { Search, PackageCheck, Phone, MapPin, Calendar, Download, Truck } from 'lucide-react';
import { fetchOrders, markOrderAsShipped, exportOrders } from '../../../services/api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // Group orders by customer phone number
  const groupOrdersByCustomer = (orders) => {
    const groups = {};
    orders.forEach(order => {
      const phone = order.shipping_info.phone;
      if (!groups[phone]) {
        groups[phone] = [];
      }
      groups[phone].push(order);
    });
    return groups;
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        const data = await fetchOrders();
        console.log('Orders:', data);
        setOrders(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    getOrders();
  }, [filter]);

  // Filter orders by date range
  const filterOrdersByDate = (orders) => {
    if (!startDate || !endDate) return orders;
    
    return orders.filter(order => {
      const orderDate = new Date(order.created_at);
      return orderDate >= startDate && orderDate <= endDate;
    });
  };

  const handleMarkAsShipped = async (orderId) => {
    try {
      await markOrderAsShipped(orderId);
      const updatedOrders = await fetchOrders();
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pre-order': return 'text-yellow-500';
      case 'pending': return 'text-yellow-500';
      case 'paid': return 'text-green-500';
      case 'shipped': return 'text-blue-500';
      case 'payment_failed': return 'text-red-500';
      case 'refund': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const getStatusText = (status) => {
    const statusMap = {
      'pre-order': 'הזמנה מקדימה',
      'pending': 'ממתין לתשלום',
      'paid': 'שולם',
      'refund': 'החזר',
      'shipped': 'נשלח',
      'payment_failed': 'תשלום נכשל'
    };
    return statusMap[status] || status;
  };

  // Get group styling for orders
  const getGroupStyles = (groups, order, orders) => {
    const customerOrders = groups[order.shipping_info.phone];
    if (!customerOrders || customerOrders.length <= 1) return '';

    const orderIndex = customerOrders.findIndex(o => o.id === order.id);
    const isFirst = orderIndex === 0;
    const isLast = orderIndex === customerOrders.length - 1;

    let classes = 'relative ';
    
    if (isFirst) {
      classes += 'border-t border-x border-purple-500/50 rounded-t-xl ';
    } else if (isLast) {
      classes += 'border-b border-x border-purple-500/50 rounded-b-xl ';
    } else {
      classes += 'border-x border-purple-500/50 ';
    }

    return classes;
  };

  // Get background color for grouped orders
  const getGroupBackground = (groups, order) => {
    const customerOrders = groups[order.shipping_info.phone];
    if (customerOrders && customerOrders.length > 1) {
      return customerOrders[0].id === order.id ? 'bg-purple-900/20' : 'bg-purple-800/10';
    }
    return '';
  };
  const filteredOrders = filterOrdersByDate(orders.filter(order => {
    const searchLower = searchTerm.toLowerCase();
    
    // Apply status filter
    if (filter !== 'all' && order.status !== filter) {
      return false;
    }
    
    // Apply search filter
    return (
      order.shipping_info.full_name?.toLowerCase().includes(searchLower) ||
      order.id.toString().includes(searchLower) ||
      order.shipping_info.phone?.includes(searchTerm) ||
      order.shipping_info.address?.toLowerCase().includes(searchLower) ||
      order.shipping_info.city?.toLowerCase().includes(searchLower) ||
      order.shipping_info.zip_code?.includes(searchTerm) ||
      order.shipping_method?.toLowerCase().includes(searchLower)
    );
  }));

  const handleExport = async (type) => {
    try {
      await exportOrders(type);
    } catch (error) {
      console.error('Error exporting orders:', error);
    }
  };

  const customerGroups = groupOrdersByCustomer(filteredOrders);

  return (
    <div className="w-full">
      {/* Header Section - Responsive */}
      <div className="mb-8 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
          <h1 className="text-2xl font-bold text-white">ניהול הזמנות</h1>
          <div className="flex gap-2">
            <button
              onClick={() => handleExport('paid')}
              className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition-colors"
            >
              <Download className="w-5 h-5" />
              <span>ייצוא הזמנות ששולמו</span>
            </button>
            <button
              onClick={() => handleExport('delivery')}
              className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
            >
              <Truck className="w-5 h-5" />
              <span>ייצוא משלוחים</span>
            </button>
          </div>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
          {/* Date Range Picker */}
          <div className="relative">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              placeholderText="בחר תאריכים"
              className="w-full sm:w-auto bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 focus:outline-none focus:border-purple-500"
              dateFormat="dd/MM/yyyy"
              isClearable={true}
            />
            <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
          </div>

          {/* Search */}
          <div className="relative flex-grow sm:flex-grow-0">
            <input
              type="text"
              placeholder="חיפוש לפי שם, טלפון או מספר הזמנה"
              className="w-full sm:w-80 bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 pr-10 focus:outline-none focus:border-purple-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
          </div>

          {/*Status filter Filter */}
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 focus:outline-none focus:border-purple-500"
          >
            <option value="all">כל ההזמנות</option>
            <option value="pre-order">הזמנה מקדימה</option>
            {/* <option value="pending">ממתין לתשלום</option> */}
            <option value="paid">שולם</option>
            <option value="shipped">נשלח</option>
            <option value="payment_failed">תשלום נכשל</option>
          </select>
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden space-y-4">
        {filteredOrders.map((order) => (
          <div 
            key={order.id}
            className={`rounded-xl p-4 space-y-3 ${getGroupBackground(customerGroups, order)} ${getGroupStyles(customerGroups, order, filteredOrders)}`}
          >
            <div className="flex justify-between items-start">
              <div className="flex items-center gap-2">
                <span className="text-white font-medium">#{order.id}</span>
                {customerGroups[order.shipping_info.phone].length > 1 && (
                  <span className="text-xs text-purple-400 bg-purple-500/20 px-2 py-1 rounded-full">
                    {customerGroups[order.shipping_info.phone].length} הזמנות
                  </span>
                )}
              </div>
              <span className={`${getStatusColor(order.status)}`}>
                {getStatusText(order.status)}
              </span>
            </div>

            <div className="pt-2 border-t border-gray-700">
              <div className="flex items-center gap-2 text-white">
                <span>{order.shipping_info.full_name}</span>
                <span>•</span>
                <span className="text-purple-500">₪{order.total_amount}</span>
              </div>
              
              <div className="flex items-center gap-2 text-gray-400 text-sm mt-1">
                <Phone className="w-4 h-4" />
                <span>{order.shipping_info.phone}</span>
              </div>
              
              <div className="flex items-center gap-2 text-gray-400 text-sm mt-1">
                <MapPin className="w-4 h-4" />
                <span>
                    {`${order.shipping_info.address} ${order.shipping_info.city}, ${order.shipping_info.zip_code}`}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center pt-2 border-t border-gray-700">
              <div className="text-sm">
                <span className="text-gray-400">משלוח: </span>
                <span className="text-white">{order.shipping_method}</span>
              </div>
              {order.status === 'pre-order' ? (
                <span className="text-sm text-gray-400">ממתין לתשלום</span>
              ) : (
                <button
                  onClick={() => handleMarkAsShipped(order.id)}
                  disabled={order.status !== 'paid'}
                  className={`p-2 rounded-lg ${
                    order.status === 'paid'
                      ? 'bg-purple-600/10 text-purple-500 hover:bg-purple-600/20'
                      : 'bg-gray-600/10 text-gray-500 cursor-not-allowed'
                  }`}
                >
                  <PackageCheck className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden md:block bg-[#13111C] rounded-xl overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-700">
              <th className="text-right p-4 text-gray-400 font-medium">מס׳ הזמנה</th>
              <th className="text-right p-4 text-gray-400 font-medium">תאריך</th>
              <th className="text-right p-4 text-gray-400 font-medium">פרטי לקוח</th>
              <th className="text-right p-4 text-gray-400 font-medium">כתובת</th>
              <th className="text-right p-4 text-gray-400 font-medium">משלוח</th>
              <th className="text-right p-4 text-gray-400 font-medium">סכום</th>
              <th className="text-right p-4 text-gray-400 font-medium">סטטוס</th>
              <th className="text-right p-4 text-gray-400 font-medium">פעולות</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr 
                key={order.id} 
                className={`hover:bg-purple-600/5 ${getGroupBackground(customerGroups, order)} ${getGroupStyles(customerGroups, order, filteredOrders)}`}
              >
                <td className="p-4">
                  <div className="flex items-center gap-2">
                    <span className="text-white">#{order.id}</span>
                    {customerGroups[order.shipping_info.phone].length > 1 && (
                      <span className="text-xs text-purple-400 bg-purple-500/20 px-2 py-1 rounded-full">
                        {customerGroups[order.shipping_info.phone].length} הזמנות
                      </span>
                    )}
                  </div>
                </td>
                <td className="p-4 text-white">
                {new Date(order.created_at).toLocaleString('he-IL', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </td>
                <td className="p-4">
                  <div className="text-white">{order.shipping_info.full_name}</div>
                  <div className="text-gray-400 text-sm">{order.shipping_info.phone}</div>
                </td>
                <td className="p-4 text-white">
                  {`${order.shipping_info.address} ${order.shipping_info.city}, ${order.shipping_info.zip_code}`}
                </td>
                <td className="p-4 text-white">{order.shipping_method}</td>
                <td className="p-4 text-white">₪{order.total_amount}</td>
                <td className="p-4">
                  <span className={`${getStatusColor(order.status)}`}>
                    {getStatusText(order.status)}
                  </span>
                </td>
                <td className="p-4">
                  {order.status === 'pre-order' ? (
                    <span className="text-sm text-gray-400">ממתין לתשלום</span>
                  ) : (
                    <button
                      onClick={() => handleMarkAsShipped(order.id)}
                      disabled={order.status !== 'paid'}
                      className={`p-2 rounded-lg ${
                        order.status === 'paid'
                          ? 'bg-purple-600/10 text-purple-500 hover:bg-purple-600/20'
                          : 'bg-gray-600/10 text-gray-500 cursor-not-allowed'
                      }`}
                    >
                      <PackageCheck className="w-5 h-5" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrdersList;
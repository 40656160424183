import React, { useEffect, useState } from 'react';
import Login from './Login';
import AdminLayout from '../Layout/AdminLayout'
import { verifyAuth } from '../../../services/api';

const AdminRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        console.log('Checking auth status...');
        const response = await verifyAuth();
        if(response.status === 'ok') {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return <AdminLayout>{children}</AdminLayout>;
};

export default AdminRoute;
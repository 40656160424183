import React, { useEffect } from 'react';
import { Lock as LockIcon } from 'lucide-react';
import { useOrder } from '../../context/OrderContext';

// import { createOrder } from '../../services/api';

// Import API_BASE_URL from your config or constants file
const PRODUCTION_API_URL = 'https://roykornblum-backend-production.up.railway.app/api';
const DEVELOPMENT_API_URL = 'http://localhost:8000/api';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? PRODUCTION_API_URL 
  : DEVELOPMENT_API_URL;


  const PaymentStep = ({ selectedPlan, shippingMethod, shippingInfo, onBack }) => {
    // const [orderId, setOrderId] = useState(null);
    const { preOrderId, restartCheckout } = useOrder();

    const terminalName = 'fxprk101194';


    
    // Calculate total based on plan type
    const calculateTotal = () => {
      const bookPrice = selectedPlan.type === 'single' 
        ? selectedPlan.priceValue
        : selectedPlan.totalPrice; // Use pre-calculated total price for bundle
      return bookPrice + (shippingMethod?.cost || 0);
    };
  
    const totalAmount = calculateTotal();
  
    // const handleCreateOrder = async (transaction_id) => {
    //   try {
    //     const orderData = {
    //       book_count: selectedPlan.type === 'single' ? 1 : selectedPlan.quantity,
    //       total_amount: totalAmount,
    //       shipping_method: shippingMethod?.method,
    //       shipping_info: {
    //         full_name: shippingInfo.fullName,
    //         email: shippingInfo.email,
    //         phone: shippingInfo.phone,
    //         address: shippingMethod?.method === 'delivery' ? shippingInfo.address : '',
    //         city: shippingMethod?.method === 'delivery' ? shippingInfo.city : '',
    //         zip_code: shippingMethod?.method === 'delivery' ? shippingInfo.zipCode : ''
    //       }
    //     };
  
    //     const response = await createOrder(orderData, transaction_id);
    //     setOrderId(response.id);
    //     console.log('Order created:', orderId);
    //     window.location.href = '/payment/success/' + response.id;
    //   } catch (error) {
    //     console.error('Error creating order:', error);
    //     window.top.location.href = '/order-failure';
    //   }
    // };
    useEffect(() => {
      if (!preOrderId || !selectedPlan || !shippingMethod || !shippingInfo) {
        console.log('Missing required information, restarting checkout');
        restartCheckout();
      }
    }, [preOrderId, selectedPlan, shippingMethod, shippingInfo, restartCheckout]);

  // Payment handling effect
  useEffect(() => {
    const handleIframeLoad = (event) => {
        const iframe = event.target;
        try {
            console.log('checking iframe content');
            const iframeContent = iframe.contentDocument || iframe.contentWindow.document;
            // If we can read the content, we know we've been redirected to our domain
            if (iframeContent.location.href.includes('/payment/success/')) {
                console.log('payment successful');
                // Extract transactionId
                const url = iframeContent.location.href;
                const order_id = url.split('/payment/success/')[1];
                console.log('order_id:', order_id);
                // handleCreateOrder(transaction_id);
                window.location.href = '/payment/success/' + order_id;
            } else if (iframeContent.location.href.includes('/payment/failure/')) {
                console.log('payment failed');
                const url = iframeContent.location.href;
                const preOrderId = url.split('/payment/failure/')[1];
                window.location.href = '/payment/failure/' + preOrderId;
            }
        } catch (error) {
            console.log('Still on Tranzila domain, waiting for redirect...');
        }
    };

    const iframe = document.querySelector('iframe[name="tranzila"]');
    if (iframe) {
        iframe.addEventListener('load', handleIframeLoad);
    }

    return () => {
        if (iframe) {
            iframe.removeEventListener('load', handleIframeLoad);
        }
    };
}, );
  
    return (
      <div className="w-full max-w-2xl mx-auto" dir="rtl">
        <div className="bg-[#13111C] p-8 rounded-2xl">
          {/* Order Summary */}
          <div className="text-white mb-6">
            <h2 className="text-2xl font-bold text-center mb-4">סיכום הזמנה</h2>
            
            <div className="bg-[#1A1827] p-4 rounded-lg space-y-3 mb-6">
              <div className="flex justify-between">
                <span className="text-gray-400">מוצר:</span>
                <span>
                  {selectedPlan.type === 'single' 
                    ? selectedPlan.title 
                    : `${selectedPlan.quantity} ספרים - ${selectedPlan.pricePerBook}₪ לספר`}
                </span>
              </div>
              {/* Products total payment amount */}
              <div className="flex justify-between">
                <span className="text-gray-400">סכום המוצרים:</span>
                <span>₪{selectedPlan.priceValue}</span>
              </div>
              {/* Bundle savings */}
              {selectedPlan.type === 'bundle' && (
                <div className="flex justify-between">
                  <span className="text-gray-400">חיסכון כולל:</span>
                  <span className="text-green-500">
                    ₪{selectedPlan.quantity * (selectedPlan.originalPricePerBook - selectedPlan.pricePerBook)}
                  </span>
                </div>
              )}
              <div className="flex justify-between">
                <span className="text-gray-400">אופן משלוח:</span>
                <span>{shippingMethod.method === 'delivery' ? 'משלוח' : 'איסוף עצמי'}</span>
              </div>
              {shippingMethod.method === 'delivery' && (
                <div className="flex justify-between">
                  <span className="text-gray-400">דמי משלוח:</span>
                  <span>₪{shippingMethod.cost}</span>
                </div>
              )}
              <div className="flex justify-between pt-3 border-t border-gray-700">
                <span className="text-gray-400">סה"כ לתשלום:</span>
                <span className="text-lg font-bold">₪{totalAmount}</span>
              </div>
            </div>
          </div>
  
          {/* Payment Form */}
        <form 
            action={`https://direct.tranzila.com/${terminalName}/iframenew.php`}
            target="tranzila"
            method="POST"
            className="space-y-6"
            noValidate
            autoComplete="off"
        >
            <input type="hidden" name="sum" value={totalAmount} />
            <input type="hidden" name="currency" value="1" />
            <input type="hidden" name="buttonLabel" value="תשלום" />
            <input type="hidden" name="bit_pay" value="1" />
            {/* <input type="hidden" name="google_pay" value="1" /> */}
            <input type="hidden" name="lang" value="il" />
            <input type="hidden" name="trBgColor" value="13111C" />
            <input type="hidden" name="trTextColor" value="FFFFFF" />
            <input type="hidden" name="trButtonColor" value="9333EA" />
            <input type="hidden" name="buyer_name" value={shippingInfo.fullName} />
            <input type="hidden" name="buyer_email" value={shippingInfo.email} />
            <input type="hidden" name="buyer_phone" value={shippingInfo.phone} />
            <input type="hidden" name="success_url_address" value={`${API_BASE_URL}/payments/success/${preOrderId}`} />
            <input type="hidden" name="fail_url_address" value={`${API_BASE_URL}/payments/failure/${preOrderId}`} />
            {/* <input type="hidden" name="notify_url_address" value={`${API_BASE_URL}/payments/webhook`} /> */}
  
            <div className="flex gap-4">
              <button
                type="button"
                onClick={onBack}
                className="flex-1 bg-gray-700 hover:bg-gray-600 text-white py-4 
                         transition-colors duration-300 rounded-xl"
              >
                חזרה
              </button>
              <button
                type="submit"
                className="flex-1 bg-purple-600 hover:bg-purple-700 text-white py-4 
                         transition-colors duration-300 rounded-xl flex items-center justify-center gap-2"
              >
                <LockIcon className="w-5 h-5" />
                המשך לתשלום
              </button>
            </div>
          </form>
  
          {/* Payment Frame */}
          <div className="mt-6 w-full h-[600px] rounded-xl overflow-hidden">
            <iframe
              name="tranzila"
              className="w-full h-full"
              // allowpaymentrequest="true"
              title="Tranzila Payment Frame"
            />
          </div>
        </div>
      </div>
    );
  };

  export default PaymentStep;
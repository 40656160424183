import React, { useState } from 'react';
import { Package, Settings, LogOut, Menu, X } from 'lucide-react';

const AdminLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem('authToken');
    window.location.href = '/admin';
  };

  return (
    <div className="min-h-screen bg-[#0A071B]" dir="rtl">
      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 right-0 left-0 z-50 bg-[#13111C] p-4 border-b border-gray-700">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold text-white">פאנל ניהול</h1>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-gray-400 hover:text-white"
          >
            {isSidebarOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="md:hidden fixed inset-0 bg-black/50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div className={`
        fixed top-0 right-0 h-full w-64 bg-[#13111C] p-6 z-50
        transform transition-transform duration-300 ease-in-out
        md:transform-none
        ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full md:translate-x-0'}
      `}>
        <div className="mb-8 mt-16 md:mt-8">
          <h2 className="text-xl font-bold text-white">ניהול הזמנות</h2>
        </div>
        
        <nav className="space-y-2">
          <a 
            href="/admin/orders" 
            className="flex items-center gap-3 text-gray-300 hover:text-white px-4 py-3 rounded-lg hover:bg-purple-600/10"
            onClick={() => setIsSidebarOpen(false)}
          >
            <Package className="w-5 h-5" />
            <span>הזמנות</span>
          </a>
          
          <a 
            href="/admin/settings" 
            className="flex items-center gap-3 text-gray-300 hover:text-white px-4 py-3 rounded-lg hover:bg-purple-600/10"
            onClick={() => setIsSidebarOpen(false)}
          >
            <Settings className="w-5 h-5" />
            <span>הגדרות</span>
          </a>
        </nav>

        <div className="absolute bottom-6 right-6 left-6">
          <button 
            onClick={handleLogout}
            className="w-full flex items-center gap-3 text-gray-300 hover:text-white px-4 py-3 rounded-lg hover:bg-red-600/10"
          >
            <LogOut className="w-5 h-5" />
            <span>התנתק</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="md:mr-64 p-4 md:p-8 pt-20 md:pt-24">
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;
import React, { useState } from 'react';
import { Phone, Mail, User, MessageSquare, Send } from 'lucide-react';
import { sendContactForm } from '../../services/api';

const ContactFooter = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const [status, setStatus] = useState({
    loading: false,
    error: null,
    success: false
  });
  
  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, error: null, success: false });

    try {
      const result = await sendContactForm(formData);

      if (result.status === 200) {
        setStatus({ loading: false, error: null, success: true });
        setFormData({ name: '', email: '', phone: '', message: '' });
        
        // Reset success message after 5 seconds
        setTimeout(() => {
          setStatus(prev => ({ ...prev, success: false }));
        }, 5000);
      }
    } catch (error) {
      setStatus({ 
        loading: false, 
        error: 'שגיאה בשליחת הטופס. אנא נסו שנית', 
        success: false 
      });
    }
  };
  
  return (
    <footer className="relative py-32 bg-gradient-to-b from-purple-900/20 to-black" dir="rtl">
      <div className="max-w-7xl mx-auto px-6">
        <div className="mb-16 text-right">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-purple-400 bg-clip-text text-transparent">
            צור קשר
          </h2>
          <p className="text-lg text-gray-300">
            נשמח לשמוע מכם! השאירו פרטים ונחזור אליכם בהקדם
          </p>
        </div>

        <div className="max-w-2xl mx-auto">
          <form onSubmit={handleSubmit} className="bg-[#0E0B14] rounded-3xl p-8 space-y-6">
           {/* Name Input */}
           <div className="relative">
              <div className="flex items-center">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="השם שלך"
                  className="w-full bg-black/40 border border-gray-800 rounded-xl px-6 py-3
                           focus:outline-none focus:ring-2 focus:ring-purple-500/40 focus:border-transparent
                           placeholder:text-gray-600 text-right"
                  required
                />
                <User className="absolute left-4 h-5 w-5 text-gray-500" />
              </div>
            </div>

            {/* Email Input */}
            <div className="relative">
              <div className="flex items-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="האימייל שלך"
                  className="w-full bg-black/40 border border-gray-800 rounded-xl px-6 py-3
                           focus:outline-none focus:ring-2 focus:ring-purple-500/40 focus:border-transparent
                           placeholder:text-gray-600 text-right"
                  required
                />
                <Mail className="absolute left-4 h-5 w-5 text-gray-500" />
              </div>
            </div>

            {/* Phone Input */}
            <div className="relative">
              <div className="flex items-center">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="מספר הטלפון שלך"
                  className="w-full bg-black/40 border border-gray-800 rounded-xl px-6 py-3
                           focus:outline-none focus:ring-2 focus:ring-purple-500/40 focus:border-transparent
                           placeholder:text-gray-600 text-right"
                  required
                />
                <Phone className="absolute left-4 h-5 w-5 text-gray-500" />
              </div>
            </div>

            {/* Message Input */}
            <div className="relative">
              <div className="flex items-center">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="ההודעה שלך"
                  rows="4"
                  className="w-full bg-black/40 border border-gray-800 rounded-xl px-6 py-3
                           focus:outline-none focus:ring-2 focus:ring-purple-500/40 focus:border-transparent
                           placeholder:text-gray-600 text-right"
                  required
                />
                <MessageSquare className="absolute left-4 top-4 h-5 w-5 text-gray-500" />
              </div>
            </div>


            {/* Status Messages */}
            {status.error && (
              <div className="text-red-500 text-sm text-center">
                {status.error}
              </div>
            )}
            
            {status.success && (
              <div className="text-green-500 text-sm text-center">
                ההודעה נשלחה בהצלחה! נחזור אליך בהקדם
              </div>
            )}

            <button
              type="submit"
              disabled={status.loading}
              className="w-full bg-purple-600 hover:bg-purple-500 disabled:bg-purple-800 
                       disabled:cursor-not-allowed text-white px-6 py-3 rounded-xl
                       transition-all duration-300 flex items-center justify-center gap-2"
            >
              {status.loading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                <>
                  <Send className="h-5 w-5" />
                  שלח הודעה
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default ContactFooter;
import React, { useState } from 'react';
import { login } from '../../../services/api';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      window.location.reload();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="min-h-screen bg-[#0A071B] flex items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-[#13111C] p-8 rounded-xl w-96">
        <h2 className="text-2xl font-bold text-white mb-6">התחברות</h2>
        <input
          type="text"
          placeholder="שם משתמש"
          className="w-full bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 mb-4"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="סיסמה"
          className="w-full bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 mb-6"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button 
          type="submit"
          className="w-full bg-purple-600 text-white py-3 rounded-lg hover:bg-purple-700"
        >
          התחבר
        </button>
      </form>
    </div>
  );
}
import React, { useState } from 'react';
import { Book, ShoppingCart, Plus, Minus, Package } from 'lucide-react';

const ProductStep = ({ onSelect }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [quantity, setQuantity] = useState(2);
  
  const ORIGINAL_PRICE = 88;
  const SINGLE_PRICE = 59;
  const BUNDLE_PRICE = 50;

  const calculateSavings = (quantity, originalPrice, discountedPrice) => {
    return quantity * (originalPrice - discountedPrice);
  };

  const plans = [
    {
      id: 'single',
      title: "ספר אחד",
      originalPrice: `₪${ORIGINAL_PRICE}`,
      price: `₪${SINGLE_PRICE}`,
      priceValue: SINGLE_PRICE,
      type: 'single'
    },
    {
      id: 'bundle',
      title: "יותר מספר אחד",
      type: 'bundle',
      priceValue: quantity * BUNDLE_PRICE,
      isSale: true
    }
  ];

  const handleQuantityChange = (change) => {
    const newQuantity = quantity + change;
    if (newQuantity >= 2 && newQuantity <= 20) {
      setQuantity(newQuantity);
    }
  };

  const handleSubmit = () => {
    if (selectedPlan) {
      const selected = {...selectedPlan};
      if (selected.type === 'bundle') {
        selected.quantity = quantity;
        selected.pricePerBook = BUNDLE_PRICE;
        selected.originalPricePerBook = ORIGINAL_PRICE;
        selected.totalPrice = quantity * BUNDLE_PRICE;
        selected.priceValue = quantity * BUNDLE_PRICE; // Add this for consistency
        selected.price = `₪${quantity * BUNDLE_PRICE}`; // Add this for ShippingStep display
        selected.savings = calculateSavings(quantity, ORIGINAL_PRICE, BUNDLE_PRICE);
        selected.title = `${quantity} ספרים`; // Update title for display
      }
      onSelect(selected);
    }
  };
  return (
    <div className="w-full max-w-2xl mx-auto" dir="rtl">
      <div className="bg-[#13111C] p-8 rounded-2xl">
        {/* Header */}
        <div className="text-white mb-6 text-center">
          <h2 className="text-2xl font-bold">בחירת מוצר</h2>
          <p className="text-purple-400 mt-2 text-sm">
            קנו 2 ספרים או יותר וקבלו כל ספר ב-₪50 בלבד!
          </p>
        </div>

        {/* Product Options */}
        <div className="space-y-4">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`p-6 border rounded-xl cursor-pointer transition-all relative
                ${selectedPlan?.id === plan.id 
                  ? 'border-purple-500 bg-purple-500/10' 
                  : 'border-gray-700 hover:border-gray-600'}`}
              onClick={() => setSelectedPlan(plan)}
            >
              {plan.isSale && (
                <div className="absolute top-0 right-6 bg-purple-600 text-white px-4 py-1 rounded-b text-sm">
                  מבצע מיוחד
                </div>
              )}
              
              <div className="flex items-center gap-4">
                <div className="bg-purple-500/20 p-3 rounded-lg">
                  {plan.type === 'bundle' ? (
                    <Package className="w-6 h-6 text-purple-500" />
                  ) : (
                    <Book className="w-6 h-6 text-purple-500" />
                  )}
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-lg text-white">{plan.title}</h4>
                  
                  {plan.type === 'bundle' ? (
                    <div className="mt-2">
                      <div className="flex items-center gap-3">
                        <span className="text-gray-500 line-through">₪{ORIGINAL_PRICE}</span>
                        <span className="text-purple-500 font-medium">₪{BUNDLE_PRICE} לספר</span>
                      </div>
                      
                      {selectedPlan?.id === plan.id && (
                        <div className="space-y-3 mt-4">
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(-1);
                                }}
                                className="p-1 rounded-lg bg-purple-500/20 hover:bg-purple-500/30 text-purple-500"
                              >
                                <Minus className="w-4 h-4" />
                              </button>
                              
                              <span className="text-white font-medium px-4">
                                {quantity}
                              </span>
                              
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(1);
                                }}
                                className="p-1 rounded-lg bg-purple-500/20 hover:bg-purple-500/30 text-purple-500"
                              >
                                <Plus className="w-4 h-4" />
                              </button>
                            </div>
                          </div>

                          <div className="space-y-1">
                            <div className="text-purple-500 font-medium text-lg">
                              סה״כ: ₪{quantity * BUNDLE_PRICE}
                            </div>
                            <div className="text-green-500 text-sm">
                              חיסכון: ₪{calculateSavings(quantity, ORIGINAL_PRICE, BUNDLE_PRICE)}
                            </div>
                            <div className="text-gray-400 text-sm mt-2">
                              * בקנייה של 2 ספרים ומעלה
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mt-2">
                      <div className="text-gray-500 line-through">{plan.originalPrice}</div>
                      <div className="text-purple-500 font-medium text-lg mt-1">
                        {plan.price}
                      </div>
                      <div className="text-green-500 text-sm">
                        חיסכון: ₪{ORIGINAL_PRICE - SINGLE_PRICE}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Continue Button */}
        <button
          onClick={handleSubmit}
          disabled={!selectedPlan}
          className={`w-full mt-8 py-4 rounded-xl transition-colors duration-300
            flex items-center justify-center gap-2
            ${selectedPlan 
              ? 'bg-purple-600 hover:bg-purple-700 text-white' 
              : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
        >
          <ShoppingCart className="w-5 h-5" />
          <span>המשך</span>
        </button>
      </div>
    </div>
  );
};

export default ProductStep;
import { createContext, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [preOrderId, setPreOrderId] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const navigate = useNavigate();

  const resetOrder = useCallback(() => {
    setPreOrderId(null);
    setOrderData(null);
  }, []);

  const restartCheckout = useCallback(() => {
    resetOrder();
    navigate('/store');
  }, [resetOrder, navigate]);

  const value = {
    preOrderId,
    setPreOrderId,
    orderData,
    setOrderData,
    resetOrder,
    restartCheckout
  };

  return (
    <OrderContext.Provider value={value}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook to use the order context
export const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('useOrder must be used within an OrderProvider');
  }
  return context;
};
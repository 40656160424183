import React, { useState } from 'react';

const InfoStep = ({ selectedPlan, shippingMethod, onBack, onNext }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zipCode: ''
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'שם מלא הוא שדה חובה';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'אימייל הוא שדה חובה';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'אנא הכנס כתובת אימייל תקינה';
    }
    
    if (!formData.phone.trim()) {
      newErrors.phone = 'טלפון הוא שדה חובה';
    } else if (!/^[0-9]{9,10}$/.test(formData.phone.replace(/[-\s]/g, ''))) {
      newErrors.phone = 'אנא הכנס מספר טלפון תקין';
    }
    
    // Only validate address fields for delivery
    if (shippingMethod?.method === 'delivery') {
      if (!formData.address.trim()) {
        newErrors.address = 'כתובת היא שדה חובה';
      }
      
      if (!formData.city.trim()) {
        newErrors.city = 'עיר היא שדה חובה';
      }
      
      if (!formData.zipCode.trim()) {
        newErrors.zipCode = 'מיקוד הוא שדה חובה';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onNext(formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const inputClassName = "w-full bg-[#1A1827] text-white border border-gray-700 rounded-lg p-3 focus:outline-none focus:border-purple-500";
  const errorClassName = "text-red-500 text-sm mt-1";

  return (
    <div className="w-full max-w-xl mx-auto" dir="rtl">
      <div className="bg-[#13111C] p-8 rounded-2xl">
        <div className="text-white mb-6">
          <h2 className="text-2xl font-bold text-center mb-2">פרטי לקוח</h2>
          <p className="text-gray-400 text-center">
            {selectedPlan.title} - {selectedPlan.price}
            {shippingMethod?.cost > 0 && ` + ₪${shippingMethod.cost} דמי משלוח`}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="text"
              name="fullName"
              placeholder="שם מלא"
              value={formData.fullName}
              onChange={handleInputChange}
              className={inputClassName}
            />
            {errors.fullName && <p className={errorClassName}>{errors.fullName}</p>}
          </div>

          <div>
            <input
              type="email"
              name="email"
              placeholder="אימייל"
              value={formData.email}
              onChange={handleInputChange}
              className={inputClassName}
            />
            {errors.email && <p className={errorClassName}>{errors.email}</p>}
          </div>

          <div>
            <input
              type="tel"
              name="phone"
              placeholder="טלפון"
              value={formData.phone}
              onChange={handleInputChange}
              className={`${inputClassName} text-right`}
              dir="rtl"
            />
            {errors.phone && <p className={errorClassName}>{errors.phone}</p>}
          </div>

          {/* Conditional address fields for delivery method */}
          {shippingMethod?.method === 'delivery' && (
            <>
              <div>
                <input
                  type="text"
                  name="address"
                  placeholder="כתובת למשלוח"
                  value={formData.address}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                {errors.address && <p className={errorClassName}>{errors.address}</p>}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    name="city"
                    placeholder="עיר"
                    value={formData.city}
                    onChange={handleInputChange}
                    className={inputClassName}
                  />
                  {errors.city && <p className={errorClassName}>{errors.city}</p>}
                </div>

                <div>
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="מיקוד"
                    value={formData.zipCode}
                    onChange={handleInputChange}
                    className={inputClassName}
                  />
                  {errors.zipCode && <p className={errorClassName}>{errors.zipCode}</p>}
                </div>
              </div>
            </>
          )}

          <div className="flex gap-4">
            <button
              type="button"
              onClick={onBack}
              className="flex-1 bg-gray-700 hover:bg-gray-600 text-white py-4 
                       transition-colors duration-300 rounded-xl"
            >
              חזרה
            </button>
            <button
              type="submit"
              className="flex-1 bg-purple-600 hover:bg-purple-700 text-white py-4 
                       transition-colors duration-300 rounded-xl"
            >
              המשך
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InfoStep;
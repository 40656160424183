import React, { useState, useEffect } from 'react';
import { ArrowRight, X, CheckCircle2, AlertCircle } from 'lucide-react';
import SiteFooter from '../Footer/SiteFooter';
import ProductStep from '../Steps/ProductStep';
import InfoStep from '../Steps/InfoStep';
import PaymentStep from '../Steps/PaymentStep';
import ShippingStep from '../Steps/ShippingStep';
import { createPreOrder } from '../../services/api';
import { useOrder } from '../../context/OrderContext';


const STEPS = {
  PRODUCT: 'product',
  SHIPPING: 'shipping',
  INFO: 'info',
  PAYMENT: 'payment'
};


const StepIndicator = ({ currentStep }) => (
  <div className="flex items-center justify-between mb-8 w-full max-w-[400px] mx-auto" dir="rtl">
    {[
      { key: 'PRODUCT', label: 'מוצר' },
      { key: 'SHIPPING', label: 'משלוח' },
      { key: 'INFO', label: 'פרטים' },
      { key: 'PAYMENT', label: 'תשלום' }
    ].map((step, index) => (
      <div key={step.key} className="flex items-center">
        <div 
          className={`w-6 h-6 rounded-full flex items-center justify-center border-2 text-sm
            ${currentStep === STEPS[step.key] 
              ? 'border-purple-500 bg-purple-500 text-white' 
              : 'border-gray-600 text-gray-400'}`}
        >
          {index + 1}
        </div>
        <div className={`mr-1 text-sm whitespace-nowrap ${currentStep === STEPS[step.key] ? 'text-white' : 'text-gray-400'}`}>
          {step.label}
        </div>
        {index < 3 && (
          <div className="mx-2 h-[2px] w-5 bg-gray-600" />
        )}
      </div>
    ))}
  </div>
);


const BookStoreSection = ({ onBack }) => {
  const [currentStep, setCurrentStep] = useState(STEPS.PRODUCT);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [notification, setNotification] = useState(null);
  const { setPreOrderId } = useOrder();


  // create pre-order
  const handlePreOrder = async (data) => {

    const calculateTotal = () => {
      const bookPrice = selectedPlan.type === 'single' 
        ? selectedPlan.priceValue
        : selectedPlan.totalPrice; // Use pre-calculated total price for bundle
      return bookPrice + (shippingMethod?.cost || 0);
    };
  
    const totalAmount = calculateTotal();

    const orderData = {
      book_count: selectedPlan.type === 'single' ? 1 : selectedPlan.quantity,
      total_amount: totalAmount,
      shipping_method: shippingMethod?.method,
      shipping_info: {
        full_name: data.fullName,
        email: data.email,
        phone: data.phone,
        address: shippingMethod?.method === 'delivery' ? data.address : '',
        city: shippingMethod?.method === 'delivery' ? data.city : '',
        zip_code: shippingMethod?.method === 'delivery' ? data.zipCode : ''
      }
    };
    console.log('Creating pre-order with data:', JSON.stringify(orderData));
    try {

      const response = await createPreOrder(orderData);
      console.log('Pre-order created:', response);
      setPreOrderId(response.id);

    } catch (error) {
      console.error('Error creating pre-order:', error);
      setNotification({ type: 'error', message: 'שגיאה ביצירת ההזמנה' });
    }
  }

  useEffect(() => {
    window.scrollTo({ 
      top: 0, 
      behavior: 'smooth' 
    });
  }, [currentStep]);


  const plans = [
    {
      id: 'single',
      title: "ספר אחד",
      originalPrice: "₪88",
      price: "₪59",
      priceValue: 59,
      type: 'single',
      isSale: true
    },
    {
      id: 'bundle',
      title: "יותר מספר אחד",
      type: 'bundle',
      pricePerBook: 50,
      originalPricePerBook: 88,
      isSale: true
    }
  ];
  

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.PRODUCT:
        return (
          <ProductStep
            plans={plans}
            onSelect={(plan) => {
              setSelectedPlan(plan);
              setCurrentStep(STEPS.SHIPPING);
            }}
          />
        );
      case STEPS.SHIPPING:
        return (
          <ShippingStep
            selectedPlan={selectedPlan}
            onBack={() => setCurrentStep(STEPS.PRODUCT)}
            onNext={(methodData) => {
              setShippingMethod(methodData);
              setCurrentStep(STEPS.INFO);
            }}
          />
        );
    case STEPS.INFO:
      return (
        <InfoStep
          selectedPlan={selectedPlan}
          shippingMethod={shippingMethod}
          onBack={() => setCurrentStep(STEPS.SHIPPING)}
          onNext={async (data) => {
            try {
              setCustomerInfo(data);
              await handlePreOrder(data);  // Wait for this to complete
              setCurrentStep(STEPS.PAYMENT);  // Only move to payment after success
            } catch (error) {
              console.error('Failed to create pre-order:', error);
              // Handle error appropriately
            }
          }}
        />
      );
      case STEPS.PAYMENT:
        return (
          <PaymentStep
            selectedPlan={selectedPlan}
            shippingMethod={shippingMethod}
            shippingInfo={customerInfo}
            onBack={() => setCurrentStep(STEPS.INFO)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen w-full overflow-x-hidden bg-[#0A071B]">
      {notification && (
        <div className="fixed top-6 left-1/2 -translate-x-1/2 z-[60] w-full max-w-md px-4">
          <div className={`rounded-lg p-4 flex items-center gap-3 shadow-lg ${
            notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          }`}>
            {notification.type === 'success' ? <CheckCircle2 className="w-6 h-6" /> : <AlertCircle className="w-6 h-6" />}
            <p className="flex-1 text-right">{notification.message}</p>
            <button onClick={() => setNotification(null)} className="text-gray-500 hover:text-gray-700">
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}

      <nav className="fixed top-0 left-0 right-0 z-50 h-24 flex items-center px-4 md:px-8 bg-[#0A071B]">
        <button 
          onClick={onBack}
          className="text-gray-400 hover:text-white flex items-center gap-2"
        >
          <ArrowRight className="w-5 h-5" />
          <span className="whitespace-nowrap">חזרה לדף הבית</span>
        </button>
      </nav>

      <main className="flex-1 w-full max-w-3xl mx-auto pt-32 pb-16 px-4">
        <StepIndicator currentStep={currentStep} />
        <div className="w-full">
          {renderStep()}
        </div>
      </main>
      
      <SiteFooter />
    </div>
  );
};

export default BookStoreSection;
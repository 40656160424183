import React from 'react';
import { ZoomIn } from 'lucide-react';

export default function BookPreviewSection({ onImageSelect, bookImages, onBuyClick }) {
  return (
    <section className="relative py-6 bg-gradient-to-b from-purple-900/20 to-black" dir="rtl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-12 max-w-5xl mx-auto">
          <div className="relative group w-full md:w-1/2">
            <div 
              className="relative transform transition-transform duration-500 hover:scale-105 cursor-pointer"
              onClick={() => onImageSelect(bookImages[1])}
            >
              <img 
                src={bookImages[1].src}
                alt={bookImages[1].alt}
                className="w-full h-auto shadow-2xl rounded"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-300 rounded flex items-center justify-center">
                <ZoomIn className="w-12 h-12 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-1/2 space-y-6">
            <h3 className="text-2xl sm:text-3xl font-bold">למה זה לא מרגיש כמו בית?</h3>
            <p className="text-base sm:text-lg md:text-xl text-gray-200 whitespace-pre-wrap max-w-prose">
              {"ספר חצוף, פתייני, וסוער המטיח בקוראים מציאות כאוטית ושסועה ומתאר חיפוש אחר תחושת שייכות שתאפשר לשרוד בה.\n\n" + 
               "היצירה של קורנבלום משקפת את המתח בין הדחף להתרחק מהבית ובין הרצון הבלתי פוסק לחזור אליו.\n\n" +
               "בדרך מקורית ושנונה הוא כותב על מכות ועל אהבה, על חברות ועל בגידה, על ניכור ועל ישראליות, ומעורר אותנו לשאול מי אנחנו באמת."}
            </p>
            <button 
              className="w-full md:w-auto bg-purple-600 text-white px-8 py-4 rounded-full hover:bg-purple-700 transition-colors duration-300 text-lg sm:text-xl font-medium"
              onClick={onBuyClick}
            >
              לרכישת הספר עכשיו
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
import React from 'react';
import { Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';  // Add this import

const SiteFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative py-8 bg-gradient-to-b from-black to-purple-900/20" dir="rtl">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 pb-8 border-b border-gray-800">
          {/* Copyright Section */}
          <div className="text-gray-400 space-y-2">
            <h4 className="text-white text-lg font-medium mb-4">זכויות יוצרים</h4>
            <p>© {currentYear} רועי קורנבלום</p>
            <p>כל הזכויות שמורות</p>
          </div>

          {/* Shipping Info */}
          <div className="text-gray-400 space-y-2">
            <h4 className="text-white text-lg font-medium mb-4">מידע על משלוחים</h4>
            <p>זמן אספקה: עד 14 ימי עסקים</p>
            <p>(לרוב מגיע עד 7 ימי עסקים)</p>
          </div>

          {/* Developer Info */}
          <div className="text-gray-400 space-y-2">
            <h4 className="text-white text-lg font-medium mb-4">פיתוח האתר</h4>
            <p>נבנה על ידי Ziv Eliyahu</p>
            <div className="flex items-center gap-4 mt-4">
              <a
                href="https://www.linkedin.com/in/ziv-eliyahu-a17483204/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300"
                aria-label="LinkedIn"
              >
                <Linkedin className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Legal Links - New Section */}
          <div className="text-gray-400 space-y-2">
            <h4 className="text-white text-lg font-medium mb-4">מידע משפטי</h4>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/terms" 
                  className="hover:text-white transition-colors duration-300"
                >
                  תנאי שימוש
                </Link>
              </li>
              <li>
                <Link 
                  to="/privacy" 
                  className="hover:text-white transition-colors duration-300"
                >
                  מדיניות פרטיות
                </Link>
              </li>
              <li>
              <Link to="/accessibility" className="hover:text-white transition-colors duration-300">
                הצהרת נגישות
              </Link>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
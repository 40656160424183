import React, { useState, useRef, useEffect } from 'react';
import { Instagram, Facebook, Youtube, Mail, ChevronDown, ChevronUp } from 'lucide-react';
import Button from '../Button/Button';
import SkipLink from '../ui/SkipLink';

const AppleMusicIcon = () => (
  <svg viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor" aria-hidden="true" role="img">
    <title>Apple Music</title>
    <path d="M20.42 17.37c-.86 1.64-1.76 2.98-3.15 3-1.38.03-1.83-.95-3.4-.95-1.58 0-2.07.92-3.38.98-1.35.06-2.38-1.77-3.25-3.4-1.77-2.64-3.13-7.46-1.31-10.72.9-1.61 2.51-2.63 4.26-2.65 1.33-.02 2.58.92 3.4.92.81 0 2.33-1.14 3.94-.97.67.03 2.55.28 3.76 2.08-.1.06-2.24 1.35-2.22 4.02.03 3.19 2.74 4.25 2.76 4.26-.02.08-.43 1.52-1.41 2.43zM14.03 3.45c.71-.89 1.25-2.12 1.11-3.37-1.07.05-2.37.74-3.13 1.65-.69.81-1.28 2.08-1.12 3.3 1.2.09 2.41-.62 3.14-1.58z"/>
  </svg>
);

const SpotifyIcon = () => (
  <svg viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor" aria-hidden="true" role="img">
    <title>Spotify</title>
    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
  </svg>
);

const TikTokIcon = () => (
  <svg viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor" aria-hidden="true" role="img">
    <title>TikTok</title>
    <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/>
  </svg>
);

export default function Navbar() {
  const [isSocialExpanded, setIsSocialExpanded] = useState(false);
  const expandableRef = useRef(null);
  const expandButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isSocialExpanded) {
        setIsSocialExpanded(false);
        expandButtonRef.current?.focus();
      }
    };

    if (isSocialExpanded) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isSocialExpanded]);

  const scrollToSection = (sectionId) => (e) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      section.focus({ preventScroll: true });
    }
  };

  const handleExpandClick = () => {
    setIsSocialExpanded(!isSocialExpanded);
  };

  const socialLinks = [
    { Icon: Instagram, href: "https://www.instagram.com/roy_kornblum/", label: "עקוב אחרי רועי קורנבלום באינסטגרם" },
    { Icon: TikTokIcon, href: "https://www.tiktok.com/@roykornblum", label: "עקוב אחרי רועי קורנבלום בטיקטוק" },
    { Icon: Facebook, href: "https://www.facebook.com/@DominantNegative/", label: "עקוב אחרי רועי קורנבלום בפייסבוק" },
    { Icon: Youtube, href: "https://www.youtube.com/channel/UCe2bz8oq8ypqTbiBLnTzbAg", label: "עקוב אחרי רועי קורנבלום ביוטיוב" },
    { Icon: SpotifyIcon, href: "https://open.spotify.com/artist/7CWhlvozar9k9zReldJmZg", label: "האזן לרועי קורנבלום בספוטיפיי" },
    { Icon: AppleMusicIcon, href: "https://music.apple.com/us/artist/roy-kornblum/1543633604", label: "האזן לרועי קורנבלום באפל מיוזיק" },
  ];


  return (
    <>
      <SkipLink />
      <nav 
        className="fixed top-0 w-full z-50 bg-black/30 backdrop-blur-lg"
        role="navigation"
        aria-label="תפריט ראשי"
      >
        <div className="max-w-7xl mx-auto px-6 py-4">
          {/* Desktop Layout */}
          <div className="hidden md:flex justify-between items-center">
            <div 
              className="flex items-center gap-4"
              role="navigation"
              aria-label="קישורי מדיה חברתית"
            >
              {socialLinks.map(({ Icon, href, label }, index) => (
                <React.Fragment key={href}>
                  <a 
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:text-purple-400 transition p-2 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-full"
                    aria-label={label}
                  >
                    <Icon aria-hidden="true" />
                  </a>
                  {index === 3 && <div className="h-4 w-px bg-white/20 mx-2" role="separator" />}
                </React.Fragment>
              ))}
            </div>

            <div className="flex items-center gap-8">
              {/* <button 
                className="hover:text-purple-400 transition p-2 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-lg"
                onClick={scrollToSection('concerts')}
                aria-label="צפה בהופעות הקרובות"
              >
                הופעות
              </button> */}
              <Button
                variant="secondary"
                className="px-6 py-2 bg-white text-black rounded-full hover:bg-purple-400 transition focus:outline-none focus:ring-2 focus:ring-purple-400"
                onClick={scrollToSection('book')}
                aria-label="לרכישת הספר"
              >
                לרכישת הספר
              </Button>
              <a 
                href="#contact"
                onClick={scrollToSection('contact')}
                className="flex items-center gap-2 text-white hover:text-purple-400 transition px-4 py-2 border border-white/20 rounded-full hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-400"
                aria-label="יצירת קשר עם רועי"
              >
                <Mail className="w-4 h-4" aria-hidden="true" />
                <span>יצירת קשר</span>
              </a>
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="md:hidden">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3">
                {socialLinks.slice(0, 2).map(({ Icon, href, label }) => (
                  <a 
                    key={href}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:text-purple-400 transition p-2 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-full"
                    aria-label={label}
                  >
                    <Icon aria-hidden="true" />
                  </a>
                ))}
                <button
                  ref={expandButtonRef}
                  onClick={handleExpandClick}
                  className="text-white hover:text-purple-400 transition p-2 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-full"
                  aria-expanded={isSocialExpanded}
                  aria-controls="expandable-social"
                  aria-label={isSocialExpanded ? "הסתר קישורי מדיה חברתית נוספים" : "הצג קישורי מדיה חברתית נוספים"}
                >
                  {isSocialExpanded ? 
                    <ChevronUp className="w-4 h-4" aria-hidden="true" /> : 
                    <ChevronDown className="w-4 h-4" aria-hidden="true" />
                  }
                </button>
              </div>

              <div className="flex items-center gap-3">
                <Button
                  variant="secondary"
                  className="px-3 py-1.5 text-sm bg-white text-black rounded-full hover:bg-purple-400 transition whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-purple-400"
                  onClick={scrollToSection('book')}
                  aria-label="לרכישת הספר החדש"
                >
                  לרכישת הספר
                </Button>
                <a
                  href="#contact"
                  onClick={scrollToSection('contact')}
                  className="flex items-center gap-1 text-white hover:text-purple-400 transition px-2 py-1.5 border border-white/20 rounded-full hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-400"
                  aria-label="צור קשר עם רועי"
                >
                  <Mail className="w-4 h-4" aria-hidden="true" />
                  <span className="text-sm">צור קשר</span>
                </a>
              </div>
            </div>


          {/* Expandable Social Icons */}
          <div
              id="expandable-social"
              ref={expandableRef}
              className={`overflow-hidden transition-all duration-300 ease-in-out ${
                isSocialExpanded ? 'h-16 opacity-100' : 'h-0 opacity-0'
              }`}
              role="region"
              aria-label="קישורי מדיה חברתית נוספים"
            >
              <div className="flex items-center justify-start gap-4 py-4">
                {socialLinks.slice(2).map(({ Icon, href, label }) => (
                  <a 
                    key={href}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:text-purple-400 transition p-2 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-full"
                    aria-label={label}
                  >
                    <Icon aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
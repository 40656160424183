import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navigation/Navbar';
import VideoHero from './components/Hero/VideoHero';
import AuthorBioSection from './components/Sections/AuthorBioSection';
import BookPreviewSection from './components/Sections/BookPreviewSection';
import BookStoreSection from './components/Sections/BookStoreSection';
import PaymentResult from './components/Payment/PaymentResult';
import ImageZoomModal from './components/ImageZoom/ImageZoomModal';
import ContactFooter from './components/Footer/ContactFooter';
// import AccessibilityWidget from './components/AccessibilityWidget';
import { useScroll } from './hooks/useScroll';
import { BOOK_IMAGES } from './constants/bookData';
import SiteFooter from './components/Footer/SiteFooter';
import AdminRoute from './components/Admin/Auth/AdminRoute';
import OrdersList from './components/Admin/Orders/OrdersList';
import TermsPage from './components/Legal/TermsPage';
import PrivacyPage from './components/Legal/PrivacyPage';
import AccessibilityPage from './components/Legal/AccessibilityPage';
import ScrollToTop from './components/Navigation/ScrollToTop';
import { OrderProvider } from './context/OrderContext';




const HomePage = ({ isPlaying, setIsPlaying, isMuted, setIsMuted, scrollPosition, 
                   selectedImage, setSelectedImage, onBuyClick }) => (
  <>
    <VideoHero
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      isMuted={isMuted}
      setIsMuted={setIsMuted}
      scrollPosition={scrollPosition}
    />
    <AuthorBioSection />
    <div id="book">
      <BookPreviewSection
        onImageSelect={setSelectedImage}
        bookImages={BOOK_IMAGES}
        onBuyClick={onBuyClick}
      />
      <ImageZoomModal 
        image={selectedImage}
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
      />
    </div>
    <div id="contact">
      <ContactFooter />
    </div>
    <SiteFooter />
  </>
);

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  const scrollPosition = useScroll();

  useEffect(() => {
    // Prevent horizontal scrolling on mobile
    document.documentElement.style.overscrollBehavior = 'none';
    document.body.style.overscrollBehavior = 'none';
    
    return () => {
      document.documentElement.style.overscrollBehavior = '';
      document.body.style.overscrollBehavior = '';
    };
  }, []);


  const handleNavigation = (path) => {
    setIsTransitioning(true);
    navigate(path);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  const getPageTitle = () => {
    if (location.pathname === '/store') return 'רכישת הספר - Roy Kornblum';
    if (location.pathname.includes('/payment')) return 'סיום רכישה - Roy Kornblum';
    return 'Roy Kornblum';
  };

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden" dir="rtl">
      <ScrollToTop />
      <Helmet>
        <title>{getPageTitle()}</title>
        <link rel="icon" href="/images/logo-black.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      
      {/* <AccessibilityWidget /> */}
      <Navbar />
      
      <div className={`transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-100'} overflow-hidden`}>
      <OrderProvider>
        <Routes>
          <Route path="/" element={
            <HomePage
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              scrollPosition={scrollPosition}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              onBuyClick={() => handleNavigation('/store')}
            />
          } />
          
          <Route path="/store" element={<BookStoreSection onBack={() => handleNavigation('/')} />} />
          <Route path="/payment/success/:orderId" element={<PaymentResult status="success" />} />
          <Route path="/payment/failure/:orderId" element={<PaymentResult status="failure" />} />
          {/* Legal routes */}
          <Route path="/terms" element={<TermsPage onBack={() => handleNavigation('/')} />} />
          <Route path="/privacy" element={<PrivacyPage onBack={() => handleNavigation('/')} />} />
          <Route path="/accessibility" element={<AccessibilityPage  onBack={() => handleNavigation('/')}/>} />
          
          {/* Admin routes */}
          <Route path="/admin" element={<Navigate to="/admin/orders" replace />} />
          <Route 
            path="/admin/orders" 
            element={
              <AdminRoute>
                <OrdersList />
              </AdminRoute>
            } 
          />
          <Route 
            path="/admin/settings" 
            element={
              <AdminRoute>
                <div className="text-white">Settings Page (Coming Soon)</div>
              </AdminRoute>
            } 
          />
        </Routes>
      </OrderProvider>
      </div>
    </div>
  );
}
import React from 'react';
import SiteFooter from '../Footer/SiteFooter';
import { ArrowRight } from 'lucide-react';

const TermsPage = ({onBack}) => {
  return (
    <div className="min-h-screen bg-black pt-24">
            {/* Navigation Area */}
      <div className="absolute top-10 left-0 right-0 h-24 flex items-center px-8">
        <button 
          onClick={onBack}
          className="text-gray-400 hover:text-white flex items-center gap-2 transition-colors duration-200"
        >
          <ArrowRight className="w-5 h-5" />
          חזרה לדף הבית
        </button>
      </div>
      <div className="max-w-4xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold text-white mb-8">תנאי שימוש</h1>
        
        <div className="prose prose-invert max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. כללי</h2>
            <p className="text-gray-300 leading-relaxed">
              ברוכים הבאים לחנות הספרים המקוונת של רועי קורנבלום. השימוש באתר זה ובשירותים המוצעים בו
              כפוף לתנאים המפורטים להלן. המשך הגלישה באתר ו/או ביצוע רכישה מהווים הסכמה מצדך לתנאים אלה.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. מוצרים ומחירים</h2>
            <p className="text-gray-300 leading-relaxed">
              2.1. המוצרים המוצעים למכירה באתר הם ספרים מקוריים של רועי קורנבלום.<br/>
              2.2. המחירים המוצגים באתר כוללים מע"מ כחוק.<br/>
              2.3. אנו שומרים לעצמנו את הזכות לשנות מחירים בכל עת וללא הודעה מוקדמת.<br/>
              2.4. הזמנה תיחשב כמאושרת רק לאחר קבלת אישור תשלום מחברת האשראי.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. משלוחים ואספקה</h2>
            <p className="text-gray-300 leading-relaxed">
              3.1. זמן האספקה המשוער הוא עד 14 ימי עסקים (לרוב מגיע תוך 7 ימי עסקים).<br/>
              3.2. המשלוח יבוצע לכתובת שצוינה בעת ההזמנה.<br/>
              3.3. עלות המשלוח מוצגת בתהליך הרכישה ומתווספת למחיר המוצר.<br/>
              3.4. במקרה של עיכוב או בעיה במשלוח, נעדכן אותך באמצעות המייל או הטלפון שסיפקת.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. מדיניות ביטולים והחזרות</h2>
            <p className="text-gray-300 leading-relaxed">
              4.1. ניתן לבטל עסקה בתוך 14 ימים מיום קבלת המוצר, בהתאם לחוק הגנת הצרכן.<br/>
              4.2. הספר חייב להיות במצב חדש, ללא סימני שימוש או פגיעה.<br/>
              4.3. החזר כספי יבוצע באמצעי התשלום בו בוצעה הרכישה.<br/>
              4.4. עלות המשלוח המקורי לא תוחזר, ועלות החזרת המוצר תחול על הלקוח.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. זכויות יוצרים וקניין רוחני</h2>
            <p className="text-gray-300 leading-relaxed">
              5.1. כל התכנים באתר, לרבות טקסטים, תמונות, עיצובים וסימני מסחר, הם קניינו של רועי קורנבלום.<br/>
              5.2. אין להעתיק, לשכפל, להפיץ או לעשות כל שימוש מסחרי בתכנים אלה ללא אישור מפורש בכתב.<br/>
              5.3. הספרים המסופקים מוגנים בזכויות יוצרים, ואין לשכפל או להפיץ אותם בכל צורה שהיא.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. יצירת קשר</h2>
            <p className="text-gray-300 leading-relaxed">
              לכל שאלה או בירור בנוגע לתנאי השימוש, ניתן ליצור קשר באמצעות טופס יצירת הקשר באתר.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. שינויים בתנאי השימוש</h2>
            <p className="text-gray-300 leading-relaxed">
              אנו שומרים לעצמנו את הזכות לעדכן ולשנות את תנאי השימוש מעת לעת.
              שינויים אלה ייכנסו לתוקף מיד עם פרסומם באתר.
            </p>
          </section>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
};

export default TermsPage;